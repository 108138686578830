export const accessPermissions = {
  guest: {
    view: ['auth-buttons', 'main-header']
  },
  client: {
    view: ['profile','my-smart', 'finance-manager', 'dashboard', 'project-contract', 'dashboard-project-status', 'render-csv-view', 'subheader', 'main-header', 'event', 'comment', 'project-step', 'conception-list', 'project-list'],
    edit: [],
    create: ['event'],
    approve: ['conception'],
    valuate: ['event']
  },
  agency: {
    parent: 'client',
    view: ['client', 'render-csv-view', 'last-projects-dates-bar-chart', 'conception-list'],
    edit: ['person'],
    valuate: ['event'],
    verify_phone: ['verify-phone']
  },
  freelancer: {
    view: ['opened-opportunity', 'finance-manager', 'finance-panel', 'last-projects-dates-bar-chart', 'profile', 'dashboard', 'project', 'project-list', 'subheader', 'main-header', 'my_portfolio', 'opportunity', 'project-item', 'attachment', 'event', 'comment'],
    edit: ['attachment', 'event', 'comment'],
    create: ['event'],
    answer: ['event'],
    approve: []
  },
  admin: {
    view: ['project-contract', 'my-smart','create-project-rd', 'notifications-whatsapps', 'finance-manager', 'finance-panel', 'project-contract-pdf', 'list-coupons', 'list-transactions', 'dashboard-project-status', 'general-adjustments', 'calculator', 'project-report-manager', 'last-projects-dates-bar-chart', 'profile', 'render-csv-view', 'product-type-crud', 'tag-crud', 'product-crud', 'project-cases-crud', 'competence-crud','smart-video-crud', 'competence-guide-crud', 'ibge-names-crud', 'ibge-name-groups-crud', 'project-basic', 'dashboard', 'conception-list', 'all_portfolio', 'sidebar', 'subheader', 'project', 'main-header', 'portfolio-manager', 'project-list', 'client', 'advanced-browser', 'render', 'bot', 'opportunity', 'event', 'comment', 'attachment', 'project-item-request', 'conception', 'project-item', 'project-step', 'advanced-conception', 'advanced-render-form-buttons', 'manage-projects', 'feedback-manager', 'delete-render', 'log-render', 'cupons-crud', 'commission-field'],
    edit: ['project-item-request', 'product', 'product-type', 'ibge-name', 'ibge-name-group', 'project-case', 'competence', 'tag', 'comment', 'event', 'attachment', 'project', 'project-item', 'portfolio-manager', 'access-permission', 'person', 'advanced-conception', 'advanced-project-item', 'event-visibility', 'archive-project'],
    approve: ['briefing', 'conception', 'opportunity', 'project-item-request', 'project-step', 'portfolio-manager'],
    valuate: ['event'],
    answer: ['event'],
    create: ['event'],
    integration: ['import-rd', 'sync-rd'],
    verify_phone: ['link-verify']
  },
  manager: {
    view: ['project-contract', 'dashboard-project-status','notifications-whatsapps', 'project-report-manager', 'last-projects-dates-bar-chart', 'profile', 'render-csv-view', 'project-basic', 'dashboard', 'conception-list', 'all_portfolio', 'sidebar', 'subheader', 'project', 'main-header', 'portfolio-manager', 'project-list', 'client', 'advanced-browser', 'render', 'bot', 'opportunity', 'event', 'comment', 'attachment', 'project-item-request', 'conception', 'project-item', 'project-step', 'advanced-conception', 'advanced-render-form-buttons', 'feedback-manager', , 'calculator'],
    edit: ['project-item-request', 'competence', 'comment', 'event', 'attachment', 'project', 'project-item', 'portfolio-manager', 'access-permission', 'person', 'advanced-conception', 'advanced-project-item', 'event-visibility', 'archive-project'],
    approve: ['briefing', 'conception', 'advanced-conception', 'opportunity', 'advanced-project-item', 'project-item-request', 'project-step', 'portfolio-manager'],
    valuate: ['event'],
    integration: ['import-rd', 'sync-rd'],
    answer: ['event'],
    create: ['event']
  },
  vendor: {
    view: ['client', 'project-contract', 'notifications-whatsapps', 'last-projects-dates-bar-chart', 'profile', 'project-basic', 'dashboard', 'project', 'project-step', 'project-list', 'subheader', 'main-header', 'project-item', 'attachment', 'event', 'comment', 'conception', 'conception-list', , 'calculator'],
    approve: ['briefing', 'conception', 'advanced-conception'],
    integration: ['import-rd', 'sync-rd'],
    edit: ['project', 'project-item', 'project-step', 'person']
  }
};
// 'project-general-tab', 'project-schedule-tab', 'project-item-tab', 'project-item-request-tab', 'project-conception-tab','project-rendering-tab'
