import {NgModule} from '@angular/core';

import {PagesRoutingModule} from './pages-routing.module';


@NgModule({
  imports: [
    PagesRoutingModule
  ]
})
export class PagesModule {
}
