export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  CLIENT = 'ROLE_USER',
  MANAGER = 'ROLE_MANAGER',
  FREELANCER = 'ROLE_FREELANCER',
  AGENCY = 'ROLE_AGENCY',
  VENDOR = 'ROLE_VENDOR',
  BASIC = 'ROLE_BASIC',
}

export const authorities = {
  ADMIN: Authority.ADMIN,
  CLIENT: Authority.CLIENT,
  FREELANCER: Authority.FREELANCER,
  AGENCY: Authority.AGENCY,
  VENDOR: Authority.VENDOR,
  MANAGER: Authority.MANAGER
};

export const authoritiesType = {
  ROLE_ADMIN: 'Administrador',
  ROLE_FREELANCER: 'Freelancer',
  ROLE_USER: 'Cliente',
  ROLE_AGENCY: 'Agência',
  ROLE_MANAGER: 'Gerente',
  ROLE_VENDOR: 'Vendedor',
};

export const personRoles: any[] = [
  {id: 'FREELANCER', title: 'Freelancer',},
  {id: 'AGENCY', title: 'Agência'},
  {id: 'MANAGER', title: 'Gerente'},
  {id: 'CLIENT', title: 'Cliente'},
  {id: 'VENDOR', title: 'Vendedor'},
  {id: 'ALL', title: 'Todos'}
];

export const allAuthorities: Authority[] = [
  Authority.ADMIN,
  Authority.CLIENT,
  Authority.FREELANCER,
  Authority.AGENCY,
  Authority.VENDOR,
  Authority.MANAGER
]; 
