import { NbMenuItem } from '../menu-putz/menu-putz.service';

import { ProjectStatus } from '../../../../models/enums/project-status.model';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'home-outline',
    name: 'dashboard',
    link: `/dashboard`,
  },
  {
    title: 'Clients',
    icon: 'people-outline',
    name: 'client',
    link: `/people/dashboard`,
  },
  {
    title: 'Concepts',
    icon: 'book-outline',
    link: `/concepts/dashboard`,
    queryParams: { projectStatus: [ProjectStatus.CONCEPTION, ProjectStatus.BRIEFING] },
    name: 'conception-list',
    pathMatch: 'full',
  },
  {
    title: 'Projects',
    icon: 'folder-outline',
    link: `/projects/dashboard`,
    name: 'project-list',
    queryParams: { projectStatus: [ProjectStatus.EXECUTION, ProjectStatus.CLOSE, ProjectStatus.SMART] },
  },
  {
    title: 'MySmart',
    icon: 'film-outline',
    name: 'my-smart',
    link: `/my-smart/dashboard`,
    pathMatch: 'full',
  },
  {
    title: 'NotificationsWpp',
    icon: 'message-circle-outline',
    name: 'notifications-whatsapps',
    link: `/notifications-whatsapps/chat`,
    pathMatch: 'full',
  },
  {
    title: 'Calculator',
    icon: 'percent-outline',
    name: 'calculator',
    link: `/projects/calculator/conception`,
    pathMatch: 'full',
  },
  {
    title: 'Opportunities',
    icon: 'bulb-outline',
    link: `/opportunities/opened/list`,
    name: 'opened-opportunity',
  },
  {
    title: 'Subscriptions',
    icon: 'file-outline',
    link: `/opportunities/dashboard`,
    name: 'opportunity',
  },
  {
    title: 'Portfolio',
    icon: 'layers-outline',
    name: 'portfolio-manager',
    children: [
      // {
      //   title: 'Meu Portfolio',
      //   icon: 'color-palette-outline',
      //   link: `/portfolios/dashboard`,
      //   name: 'my_portfolio',
      // },
      {
        title: 'Portfolios',
        isChildren: true,
        icon: 'color-palette-outline',
        link: `/manager/portfolios`,
      }
    ]
  },
  {
    title: 'SmartVideos',
    icon: 'video-outline',
    name: 'smart-video-crud',
    children: [
      {
        title: 'Smart Projects',
        // icon: 'folder-outline',
        link: `/projects/dashboard`,
        name: 'project-list',
        isChildren: true,
        queryParams: { projectStatus: [ProjectStatus.SMART] },
      },
      {
        title: 'Renders',
        isChildren: true,
        link: `/admin/project/render/dashboard`,
      },
      // {
      //   title: 'Project Render Itens',
      //   link: `/projects/1340/render/all/table`,
      //   name: 'project-cases-crud',
      // },
      {
        title: 'Render Itens',
        isChildren: true,
        link: `projects/renders/renderItems/table`,
      },
      {
        title: 'Render Bots',
        isChildren: true,
        link: `/admin/bots/`,
      },
      {
        title: 'Smart Names',
        isChildren: true,
        link: `/admin/ibge/names/dashboard/table`,
      },
      {
        title: 'Smart Group Names',
        isChildren: true,
        link: `/admin/ibge/name/groups/dashboard/table`,
      },
      {
        title: 'Modelo de Vídeo',
        isChildren: true,
        name: 'render-model',
        link: `/models/list`,
      }
    ]
  },
  {
    title: 'Finances',
    icon: 'gift-outline',
    name: 'finance-manager',
    children: [
      {
        title: 'Transações',
        isChildren: true,
        link: `/transactions/dashboard`,
      },
      {
        title: 'Cupons',
        isChildren: true,
        link: `/coupons/dashboard`,
      },
      {
        title: 'Painel Financeiro',
        isChildren: true,
        name: 'finance-panel',
        link: `/finances/dashboard`,
      },
    ]
  },
  {
    title: 'Admin',
    icon: 'options-2-outline',
    name: 'competence-crud',
    children: [
      {
        title: 'Competência',
        isChildren: true,
        link: `/admin/competences/dashboard`,
      },
      {
        title: 'Guia de competência',
        isChildren: true,
        link: `/admin/competences/guides/dashboard`,
      },
      {
        title: 'Tags',
        isChildren: true,
        link: `/admin/tags/dashboard`,
      },
      {
        title: 'Produtos',
        isChildren: true,
        link: `/admin/products/dashboard`,
      },
      {
        title: 'Tipos de Produto',
        isChildren: true,
        link: `/admin/products/types/dashboard`,
      },
      {
        title: 'Project Cases',
        isChildren: true,
        link: `/admin/project/cases/dashboard`,
      },
      {
        title: 'Gerenciar Projetos',
        isChildren: true,
        link: `/admin/projects/dashboard`,
        queryParams: { projectStatus: [ProjectStatus.MODEL] },
      },
      {
        title: 'General Adjustments',
        icon: 'settings-2-outline',
        name: 'general-adjustments',
        link: `/admin/general-adjustments`,
        pathMatch: 'full',
      }
    ]
  }
];

