import { NgModule } from '@angular/core';
import {SafePipe} from './safe.pipe';
import { EmptyPipe } from './empty.pipe';
import { ConvertPipe } from './convert.pipe';
import { MomentToDatePipe } from './moment-to-date.pipe';
import { HasAccessPipe } from './has-access.pipe';
import { AttachmentTypePipe } from './attachment-type.pipe';
import {TruncatePipe} from "./truncate.pipe";


@NgModule({
  declarations: [
    SafePipe,
    EmptyPipe,
    ConvertPipe,
    MomentToDatePipe,
    HasAccessPipe,
    AttachmentTypePipe,
    TruncatePipe
  ],
  exports: [
    SafePipe,
    EmptyPipe,
    ConvertPipe,
    MomentToDatePipe,
    HasAccessPipe,
    AttachmentTypePipe,
    TruncatePipe
  ]
})
export class PipeModule { }
