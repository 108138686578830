import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../../../services/sidebar.service';
import { LayoutService } from '../../../core/utils/layout.service';
import { NbAccessChecker } from '@nebular/security';
import { MenuItemsService } from "../../../core/utils/menu-items.service";
import { environment } from "../../../../environments/environment";
import { ConfigurationService } from "../../../services/config/configuration.service";
import { MENU_ITEMS } from '../main-app-layout-component/menu_data/menu.component';
import { NbMenuItem } from '../main-app-layout-component//menu-putz/menu-putz.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd, Event } from "@angular/router";
import { combineLatest } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { Authority } from 'src/app/constants/authority.constants';

@Component({
  selector: 'app-main-app-one-column-layout',
  templateUrl: './main-app-one-column-layout.component.html',
  styleUrls: ['./main-app-one-column-layout.component.scss'],
  providers: [
    SidebarService
  ]
})
export class MainAppOneColumnLayoutComponent implements OnInit {
  subheader = true;
  isClient = false;
  isProd = environment.production;
  managementInfo: any;
  breadcrumbs;
  menu = MENU_ITEMS;
  constructor(
    public menuItemsService: MenuItemsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private accountService: AccountService,
    private accessChecker: NbAccessChecker,
    public sidebarService: SidebarService,
    private configurationService: ConfigurationService
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    )
      .subscribe((route: Event) => {
        if (route instanceof NavigationEnd) {
          this.breadcrumbs = this.buildBreadCrumb(route.url);
        }
      });
  }

  ngOnInit(): void {
    combineLatest([
      this.accountService.accountSubject,
    ]).subscribe(([account]) => {
      if (account) {
        if(account.hasOnlyAuthority([Authority.CLIENT])){
          this.isClient = true;
        }
        for (let item of this.menu) {
          if ((!account.hasAnyAuthority([Authority.ADMIN]) && !account.hasAnyAuthority([Authority.FREELANCER])) && item.name === 'finance-manager') {
            for (let i = 0; i < item.children.length; i++) {
              if (item.children[i]?.name && item.children[i].name === 'finance-panel') {
                item.children.splice(i, 1);
              }
            }
          }
          this.verifyAccess(item);
        }
      }
    });
    for (let item of this.menu) {
      if (this.isProd && item.name === 'smart-video-crud') {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i]?.name && item.children[i].name === 'render-model') {
            item.children.splice(i, 1);
          }
        }
      }
      this.verifyAccess(item);
    }

    if (this.isProd) {

    }

    this.accessChecker.isGranted('view', 'subheader').subscribe(hasAccess => {
      this.subheader = hasAccess;
      this.menuItemsService.getMainAppItems();
    });
    this.getManagementInfo();
  }

  verifyAccess(item: NbMenuItem): any {
    if (item) {
      this.accessChecker.isGranted('view', item.name).subscribe(value => {
        item.hidden = !value;
        return item;
      });
    } else {
      return null;
    }

  }

  buildBreadCrumb(url: string = '', breadcrumbs = []): any {
    const breadCrumb = url.split('/');
    breadCrumb.splice(0, 1)
    for (let i = 0; i < breadCrumb.length; i++) {
      if (breadCrumb[i].includes('?')) {
        breadCrumb[i] = breadCrumb[i].split('?')[0];
      }

    }

    return breadCrumb;
  }

  getManagementInfo() {
    this.configurationService.getManagementInfo().subscribe(data => {
      this.managementInfo = data;
    }, error => {
      console.log(error);
    }
    );
  }
}
