<nb-layout>
  <nb-layout-header class="main-header" [fixed]="true" [hidden]="layoutService.hideHeader">
    <!--    *nbIsGranted="['view','main-header']"-->
    <app-header></app-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive *ngIf="subheader">
    <nb-menu [items]="menu"></nb-menu>
    <ng-container *ngIf="isClient && !sidebarService.compact">
      <hr>
      <h5 style="text-align: center; font-weight: bold;">Painel Smart</h5>
      <div class="client-info">
        <p class="info">Projetos ativos: <span style="font-weight: bold;">2</span></p>
        <p class="info">SmartVideos produzidos: <span style="font-weight: bold;">1428/3000</span></p>
        <p class="info">Créditos: <span style="font-weight: bold;">240</span></p>
        <p class="info">Cupons: <span style="font-weight: bold;">1</span></p>
      </div>
    </ng-container>
  </nb-sidebar>

  <nb-layout-header subheader class="subheader d-none d-lg-block" *ngIf="subheader">
    <!-- <div class="breadcrumb_area">
      <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs">
          <span routerLinkActive="router-link-active">
            {{ breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1) }}
            <nb-icon icon="chevron-right"></nb-icon>
          </span>
        </li>
      </ol>
    </div> -->
    <xng-breadcrumb></xng-breadcrumb>
  </nb-layout-header>
  <!-- <nb-sidebar fixed responsive (stateChange)="sidebarService.changed($event)" tag="menu-sidebar" state="collapsed"
              [collapsedBreakpoints]="['xs', 'is', 'sm','md','lg','xl','xxl','xxxl']" [compactedBreakpoints]="[]">
    <app-sidebar></app-sidebar>
  </nb-sidebar> -->
  <nb-layout-column class="layout-column" [ngClass]="(!layoutService.hideSubheader)?'mt-lg-5':''"
    [ngClass]="(layoutService.fullScreen)?'full-screen-layout mt-lg-5':'mt-lg-5'">
    <div *ngIf="!isProd" class="bg-warning text-black text-uppercase">Aplicação usando banco de dados de testes</div>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-layout-footer>
    <div class="d-flex flex-column align-items-center justify-content-between">
      <div class="col-12 text-start">Created with ♥ by <b><a href="https://www.putzfilmes.com"
            target="_blank">Putz</a></b> 2024</div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-between">
      <ng-container *ngIf="!isProd">
        <h6 class="fw-bold poppins text m-1">Versão de Testes</h6>
      </ng-container>
      <ng-container *ngIf="isProd &&  managementInfo">
        <h6 class="fw-bold poppins text m-1" *ngIf="managementInfo?.build?.version">
          Versão: <span *ngIf="managementInfo?.activeProfiles[0]">{{managementInfo.activeProfiles[0]}}</span>
          <span class="m-0" *ngIf="managementInfo?.build?.time">{{managementInfo.build.time | date:'dd/MM/yyyy'}}</span>
        </h6>
      </ng-container>
    </div>
  </nb-layout-footer>
</nb-layout>
<ngx-guided-tour [doneText]="'Fechar'" [nextText]="'Prox.'" [closeText]="'Fechar'"
  [backText]="'Voltar'"></ngx-guided-tour>