import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {SubHeaderComponent} from './sub-header/sub-header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {RouterModule} from '@angular/router';
import {
    NbActionsModule, NbButtonGroupModule,
    NbButtonModule,
    NbCardModule,
    NbContextMenuModule,
    NbIconModule,
    NbListModule,
    NbMenuModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbSpinnerModule,
    NbTagModule,
    NbBadgeModule,
    NbSelectModule,
    NbTooltipModule,
    NbUserModule
} from '@nebular/theme';
import {PipeModule} from '../../../core/pipes/pipe.module';
import {NbSecurityModule} from '@nebular/security';
import {GuidedTourService} from '../../../../../components/guided-tour/src/lib/guided-tour.service';
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    HeaderComponent,
    SubHeaderComponent,
    SidebarComponent
  ],
  exports: [
    HeaderComponent,
    SubHeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NbActionsModule,
    NbContextMenuModule,
    NbPopoverModule,
    NbUserModule,
    NbCardModule,
    NbBadgeModule,
    NbFormFieldModule,
    NbSelectModule,
    NbIconModule,
    NbListModule,
    NbSpinnerModule,
    PipeModule,
    NbTagModule,
    NbButtonModule,
    NbMenuModule,
    NbTooltipModule,
    NbSecurityModule,
    NbButtonGroupModule,
    TranslateModule
  ],
  providers: [GuidedTourService],
})
export class MainAppLayoutComponentModule {
}
