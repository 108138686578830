import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {NbSecurityModule} from '@nebular/security';
import {CoreModule} from './core/core.module';
import {ThemesModule} from './shared/themes/themes.module';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {PagesModule} from './pages/pages.module';
import {CurrencyPipe, PercentPipe} from '@angular/common';
import {PipeModule} from './core/pipes/pipe.module';
import {NebularModule} from './shared/nebular-components/nebular.module';
import {CookieModule} from './core/cookie/browser/cookie.module';
import {environment} from '../environments/environment';
import {MainAppLayoutPageModule} from './shared/main-app-layout-page/main-app-layout-page.module';
import {NbMenuInternalService, NbMenuService} from './shared/main-app-layout-page/main-app-layout-component/menu-putz/menu-putz.service';
import {NbLayoutDirectionService} from './shared/main-app-layout-page/main-app-layout-component/menu-putz//services/direction.service';
import {MenuSiteService} from "../../projects/smart-site/src/app/services/menu-site.service";
import {NgxEchartsModule} from 'ngx-echarts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SmartVideosMapService } from './entities/render-shared/render-detail/render-detail-charts-tab/map/smart-videos-map.service';
import { NbChatModule } from '@nebular/theme';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    CookieModule.forRoot(),
    CoreModule.forRoot({appId: 0, baseAppURL: environment.BASE_APP_URL}),
    NbSecurityModule,
    NebularModule,
    ThemesModule,
    MainAppLayoutPageModule,
    PagesModule,
    NbChatModule.forRoot({ messageGoogleMapKey: 'MAP_KEY' }),
    AppRoutingModule,
    PipeModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    LeafletModule,
  ],
  providers: [CurrencyPipe, PercentPipe, MenuSiteService, NbMenuInternalService, NbMenuService, NbLayoutDirectionService, SmartVideosMapService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
