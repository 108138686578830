export enum ProjectStatus {
  MODEL = 'MODEL',
  CONCEPTION = 'CONCEPTION',
  BRIEFING = 'BRIEFING',
  EXECUTION = 'EXECUTION',
  CANCELED = 'CANCELED',
  SMART = 'SMART',
  CLOSE = 'CLOSE',
  ARCHIVED = 'ARCHIVED',
}
