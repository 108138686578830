import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainAppOneColumnLayoutComponent } from './main-app-one-column-layout/main-app-one-column-layout.component';
import {NbLayoutModule, NbSidebarModule, NbIconModule} from '@nebular/theme';
import {RouterModule} from '@angular/router';
import {MainAppLayoutComponentModule} from './main-app-layout-component/main-app-layout-component.module';
import {GuidedTourModule} from '../../../../components/guided-tour/src/lib/guided-tour.module';
import {NbMenuPutzModule} from './main-app-layout-component/menu-putz/menu-putz.module';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {BreadcrumbModule} from 'xng-breadcrumb';
@NgModule({
  declarations: [
    MainAppOneColumnLayoutComponent
  ],
    imports: [
        CommonModule,
        NbLayoutModule,
        NbIconModule,
        NbMenuPutzModule,
        BreadcrumbModule,
        RouterModule,
        MainAppLayoutComponentModule,
        NbSidebarModule,
        GuidedTourModule,
        FontAwesomeModule
    ],
})
export class MainAppLayoutPageModule { }
