<ul class="navbar-nav">
  <ng-container *ngFor="let item of headerItems;">
    <ng-container *ngIf="!item.hidden">
      <ng-container *ngIf="item?.link">
        <a class="text-uppercase fw-bold poppins header-title {{'item-'+item.name}}" [routerLink]="[item.link]"
           [queryParams]="item.queryParams" routerLinkActive="is-active"
           [routerLinkActiveOptions]="myMatchOptions">
          <nb-icon [icon]=item?.icon></nb-icon> {{'mainmenu.'+item?.title | translate}}
        </a>
      </ng-container>
      <ng-container *ngIf="item?.url">
        <a class="text-uppercase fw-bold poppins header-title {{'item-'+item.name}}" [href]="item.url"
           [target]="item.target">{{item.title}}
        </a>
      </ng-container>
      <ng-container *ngIf="!item?.link && item?.children">
        <a class="text-uppercase fw-bold poppins header-title {{'item-'+item.name}}"
           [nbContextMenu]="item?.children">
          <nb-icon [icon]=item?.icon></nb-icon> {{'mainmenu.'+item?.title | translate}}
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
  <li class="align-self-center ms-auto ms-2 d-none d-lg-inline" nbTooltip="Introdução às paginas">
    <button nbButton size="medium" ghost (click)="buildGuidedTour()">
      <nb-icon icon="question-mark-circle-outline"></nb-icon>
    </button>
  </li>
</ul>
